import React from 'react'

type Props = {
    title: string
    desc: string
}

export const Service: React.FC<Props> = (props: Props) => {
    return (
        <a>
            <div className="text">
                <h5>{props.title}</h5>
                <span>{props.desc}</span>
            </div>
            <div className="arrows">
                <i className="bi bi-caret-right-fill"></i>
            </div>
        </a>
    )
}
